/* You can add global styles to this file, and also import other style files */

@import './app/color-palette.less';

* {
  box-sizing: border-box;
  font-family: 'Inter', 'Noto Sans KR', sans-serif;
}

body {
  margin: 0;
  color: @main-text;
  box-sizing: border-box;
  font-size: 14px;
  letter-spacing: normal !important;
}

div {
  box-sizing: border-box;
}

section {
  box-sizing: border-box;
}

.admin-input {
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 10px;
  border: unset;
  outline: 1px solid @border-light-gray;
  transition: outline 0.2s ease-in-out, background-color 0.2s ease-in-out;
  height: 34px;
  background-color: white;

  &:disabled {
    background-color: @background-gray;
  }

  &::placeholder {
    color: @border-light-gray;
  }
}

form.admin-form {
  box-sizing: border-box;
  border-radius: 6px;
  border: unset;
  outline: 1px solid @border-light-gray;
  transition: outline 0.2s ease-in-out, background-color 0.2s ease-in-out;
  height: 34px;
  background-color: white;
  display: flex;

  & button {
    flex-shrink: 0;
    border: none;
    outline: none;
    width: 34px;
    height: 34px;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: @pale-gray;
    }
  }
  & input {
    outline: none;
    border: none;
    width: 100%;
    height: 34px;
    padding: 0 10px;
    background-color: transparent;
  }

  &:focus-within {
    outline: 1px solid @cool-gray-2;
  }
}

input.admin-input:focus {
  outline: 1px solid @border-focus-gray;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis-multi {
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: @pale-gray-2;
}
